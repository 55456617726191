import React, { useState, useEffect, useRef, useMemo } from "react";
import { Stage, Layer, Image as KonvaImage, Line, Group } from "react-konva";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate, useParams,useLocation } from 'react-router-dom'; 

 class TileCache {
    constructor() {
        this.dbName = 'tileCache';
        this.storeName = 'tiles';
        this.db = null;
    }

    async init() {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName, 1);

            request.onerror = () => reject(request.error);
            request.onsuccess = () => {
                this.db = request.result;
                resolve();
            };

            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                if (!db.objectStoreNames.contains(this.storeName)) {
                    db.createObjectStore(this.storeName, { keyPath: 'id' });
                }
            };
        });
    }

    async getTile(imageId, tileId) {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(this.storeName, 'readonly');
            const store = transaction.objectStore(this.storeName);
            const key = `${imageId}_${tileId}`;
            const request = store.get(key);

            request.onsuccess = () => resolve(request.result);
            request.onerror = () => reject(request.error);
        });
    }

    async saveTile(imageId, tileId, tileData) {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(this.storeName, 'readwrite');
            const store = transaction.objectStore(this.storeName);
            const key = `${imageId}_${tileId}`;
            const request = store.put({
                id: key,
                imageId,
                tileId,
                data: tileData,
                timestamp: Date.now()
            });

            request.onsuccess = () => resolve();
            request.onerror = () => reject(request.error);
        });
    }
}

export default function DynamicChunk({ jsonData }) {
    const navigate = useNavigate();
    const { imageId } = useParams();
    // const [tileCache] = useState(new TileCache());

    const location = useLocation(); 
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const stationInURL = searchParams.get('station'); 
         
        if (typeof stationInURL!='undefined' && stationInURL!=null && stationInURL!='null' && stationInURL!='') {
            handlePolygonClick(stationInURL);
        } 
    }, [location.search]);

    const tileCache = useMemo(() => {
        const cache = new Map();
        return {
            get: (key) => cache.get(key),
            set: (key, value) => cache.set(key, value),
            has: (key) => cache.has(key)
        };
    }, []);

    
    // Initialize cache when component mounts
    // useEffect(() => {
    //     tileCache.init();
    // }, []);


    const [tiles, setTiles] = useState([]);
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState(null);
    const [sidePanelOpen, setSidePanelOpen] = useState(false);
    const [panelContentUrl, setPanelContentUrl] = useState("");
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    //    const [lastPosition, setLastPosition] = useState(null);
    const [lastDistance, setLastDistance] = useState(0);
    const [tileSize, setTileSize] = useState(0);
    const [loading, setLoading] = useState(true);
    const stageRef = useRef(null);
    const [stationApi, setStationApi] = useState(new Map());
    const imageName = jsonData.images.find(img => img.id === 1).file_name;
    const [myCart, setMyCart] = useState([]);
    const [cartTotal, setCartTotal] = useState(0);
    const annotations = jsonData.annotations.filter(anno => anno.image_id === 1);
    const [drag, setDrag] = useState(true);

    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [searchOpen, setSearchOpen] = useState(false);
    const searchBarRef = useRef(null);

    const [isMouseOverSidebar, setIsMouseOverSidebar] = useState(false);
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const [selectedPolygonId, setSelectedPolygonId] = useState(null);
    const [activeStationId, setActiveStationId] = useState(false);
   
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


    // DONOR CARD
    const [isCardPopupOpen, setIsCardPopupOpen] = useState(false);
    const [polyDonations, setPolyDonations] = useState({
        donors:[],
        honorees:[],
        nations:[],
        collection:'-',
        base_amount:'$210',
        required_amount:'-'
    });


    //images&staitions
    const [imgStations,setImageStations]=useState({
        '/':[51532,53841],
        '/image1':[53842,56162],
        '/image2':[56163,57704],
    });




   

    const CardPopup = ({ onClose, stationId, setSidePanelOpen, setPanelContentUrl }) => {
        const openStationCard = () => {
            setSidePanelOpen(true); // Open side panel
            setIsCardPopupOpen(false); // Close card popup
            const url = `${process.env.REACT_APP_CARD_URL}/?frameId=${process.env.REACT_APP_FRAME_ID}&stationId=${stationId}`;
            setPanelContentUrl(url);
        };
    
        return (
            <div 
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[2000] card-popup"
            onClick={onClose}
        >
            <div 
                className={`fixed ${isMobile ? 'bottom-0' : 'bottom-0 left-0'} 
                ${isMobile ? 'h-[50%] w-full' : 'h-[80%] md:w-[20%] w-[30%]'}
                bg-white shadow-md z-[1000] flex flex-col`} 
                onClick={e => e.stopPropagation()}
            >
                {/* Header Section */}
                <div className="h-12 bg-[#eeeeee] flex justify-between items-center px-4 shrink-0">
                    {/* Logo */}
                    <div className="flex items-center">
                        <img 
                            src="/magdala-logo.svg" 
                            alt="Magdala" 
                            className="h-8"
                        />
                    </div>
                    {/* Close Button (X) */}
                    <div 
                        className="w-7 h-7 border border-[#823A5E] rounded flex justify-center items-center text-[#823A5E] font-bold cursor-pointer"
                        onClick={onClose} // Ensure the close button triggers onClose
                    >
                        X
                    </div>
                </div>
        
                {/* Content Section */}
                <div className="flex-1 overflow-y-auto px-4 py-3">
                    {/* Mosaic Image */}
                    <div className="mb-4">
                        <img 
                            src="/butterfly.jpeg" 
                            alt="Mosaic" 
                            className="w-full rounded-lg"
                        />
                    </div>
        
                    {/* Logos Row */}
                    <div className="flex justify-between items-center mb-4">
                        <img src="/magdalasecure.png" alt="Magdala" className="h-8" /> 
                    </div>
        
                    {/* Station ID and Amount */}
                    <div className="mb-4">
                        <div className="text-xl font-bold">#{stationId}</div>
                        <div className="text-lg">Base Amount: {polyDonations.base_amount}</div>
                    </div>
        
                    {/* Donors Section */}
                    <div className="mb-4">
                        <div className="font-semibold mb-2 text-left">Donors</div>
                        <div className="flex flex-wrap gap-2">
                            {polyDonations.donors.map((donor, index) => (
                                <div 
                                    key={index}
                                    className="px-3 py-1 rounded-full text-sm"
                                    style={{ 
                                        backgroundColor: index % 2 === 0 ? '#FFF3E0' : '#E3F2FD',
                                        border: '1px solid #E0E0E0'
                                    }}
                                >
                                    {donor}
                                </div>
                            ))}
                            {(polyDonations.donors && polyDonations.donors.length)<=0 ?'-': ''}
                        </div>
                    </div>
        
                    {/* Honorees Section */}
                    <div className="mb-4">
                        <div className="font-semibold mb-2 text-left">Honorees</div>
                        <div className="flex flex-wrap gap-2">
                            {polyDonations.honorees.map((honoree, index) => (
                                <div 
                                    key={index}
                                    className="px-3 py-1 rounded-full text-sm"
                                    style={{ 
                                        backgroundColor: index % 2 === 0 ? '#F3E5F5' : '#E8F5E9',
                                        border: '1px solid #E0E0E0'
                                    }}
                                >
                                    {honoree}
                                </div>
                            ))}
                            {(polyDonations.honorees &&  polyDonations.honorees.length<=0) ?'-': ''}
                        </div>
                    </div>
        
                    {/* Donation Nations */}
                    <div className="mb-6">
                        <div className="font-semibold mb-2 text-left">Donation Nations</div>
                        <div className="flex flex-wrap gap-2">
                            {polyDonations.nations.map((nation, index) => (
                                <div 
                                    key={index}
                                    className="px-3 py-1 rounded-full text-sm"
                                    style={{ 
                                        backgroundColor: '#F5F5F5',
                                        border: '1px solid #E0E0E0'
                                    }}
                                >
                                    {nation}
                                </div>
                            ))}
                            {(polyDonations.nation &&  polyDonations.nation.length<=0) ?'-': ''}
                        </div>
                    </div>
                </div>
        
                {/* Action Buttons */}
                <div className="h-16 px-4 py-2 border-t shrink-0">
                    <div className="flex gap-4">
                        <button 
                            className="flex-1 px-4 py-2 border border-gray-300 rounded-lg"
                            onClick={openStationCard} // Changed from onClose to openStationCard
                        >
                            Station Card
                        </button>
                        <button 
                            className="flex-1 px-4 py-2 bg-[#823a5e] text-white rounded-lg"
                            onClick={() => {
                                addToCart();
                                // onClose();
                            }}
                        >
                            Add To Cart
                        </button>
                    </div>
                </div>
            </div>
        </div>
        );
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
            if (window.innerWidth > 768) {
                setIsSidebarOpen(false);
            }
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    

    useEffect(() => { 
        getMyCart();
        const stationIds = annotations.map(item => item.station_id).join(',');
        stationOpacity(stationIds); 
    }, []);
     
    useEffect(() => {
       
        const initializeAndLoadTiles = async () => {
            try {
                // Only fetch metadata if we don't have it yet
                if (imageSize.width === 0 || imageSize.height === 0) {
                    const metadataResponse = await fetch(
                        `${process.env.REACT_APP_API_URL}/api/images/${imageName}/metadata`
                    );
                    const metadata = await metadataResponse.json();
                    console.log('META',metadata);
                    
                    if (!metadata.imageSize || !metadata.tileSize) {
                        throw new Error('Invalid metadata received');
                    }
    
                    // Set initial dimensions and scale
                    const viewportWidth = window.innerWidth;
                    const viewportHeight = window.innerHeight;
                    const scaleX = (viewportWidth * 0.8) / metadata.imageSize.width;
                    const scaleY = (viewportHeight * 0.8) / metadata.imageSize.height;
                    const initialScale = Math.min(scaleX, scaleY);
    
                    setImageSize(metadata.imageSize);
                    setTileSize(metadata.tileSize);
                    setScale(initialScale);
                    setPosition({
                        x: (viewportWidth - (metadata.imageSize.width * initialScale)) / 2,
                        y: (viewportHeight - (metadata.imageSize.height * initialScale)) / 2
                    });
                }
    
                // Only load tiles if we have valid dimensions
                if (imageSize.width > 0 && imageSize.height > 0 && tileSize > 0) {
                    const cols = Math.ceil(imageSize.width / tileSize);
                    const rows = Math.ceil(imageSize.height / tileSize);
    
                    // Create a Set to track which tiles we've already loaded
                    const loadedTileIds = new Set(tiles.map(tile => tile.id));
    
                    // Load only missing tiles
                    const batchSize = 4;
                    for (let y = 0; y < rows; y += batchSize) {
                        for (let x = 0; x < cols; x += batchSize) {
                            const promises = [];
                            for (let dy = 0; dy < batchSize && y + dy < rows; dy++) {
                                for (let dx = 0; dx < batchSize && x + dx < cols; dx++) {
                                    const tileId = `tile-${x + dx}-${y + dy}`;
                                    if (!loadedTileIds.has(tileId)) {
                                        promises.push(loadTile(x + dx, y + dy, tileSize));
                                    }
                                }
                            }
                            if (promises.length > 0) {
                                await Promise.all(promises);
                            }
                        }
                    }
                }
    
                setLoading(false);
    
            } catch (error) {
                console.error("Error in initializeAndLoadTiles:", error);
                setLoading(false);
            }
        };
    
        initializeAndLoadTiles();
    }, [imageName, imageSize.width, imageSize.height, tileSize]);

  
    // const loadTile = async (x, y, tileSize) => {
    //     return new Promise((resolve, reject) => {
    //         const tileId = `tile-${x}-${y}`;
            
    //         // Check if tile already exists
    //         if (tiles.some(t => t.id === tileId)) {
    //             resolve();
    //             return;
    //         }
    
    //         const img = new Image();
    //         img.crossOrigin = "anonymous";
            
    //         const width = Math.min(tileSize, imageSize.width - (x * tileSize));
    //         const height = Math.min(tileSize, imageSize.height - (y * tileSize));
    
    //         if (width <= 0 || height <= 0) {
    //             resolve();
    //             return;
    //         }
    
    //         img.onload = () => {
    //             setTiles(prevTiles => {
    //                 if (prevTiles.some(t => t.id === tileId)) {
    //                     return prevTiles;
    //                 }
    //                 return [...prevTiles, {
    //                     id: tileId,
    //                     x,
    //                     y,
    //                     width: img.naturalWidth,
    //                     height: img.naturalHeight,
    //                     image: img
    //                 }];
    //             });
    //             resolve();
    //         };
    
    //         img.onerror = reject;
     
    //         const imageUrl = `${process.env.REACT_APP_API_URL}/api/images/${imageName}/tile_${x}_${y}.png`;
    //         img.src = imageUrl;
    //     });
    // };
    

    
    // const loadTile = async (x, y, tileSize) => {
    //     const tileId = `tile-${x}-${y}`;
        
    //     try {
    //         // Try to get tile from cache first
    //         const cachedTile = await tileCache.getTile(imageId, tileId);
    //         if (cachedTile) {
    //             const img = new Image();
    //             img.src = cachedTile.data;
    //             return handleTileLoad(img, x, y, tileId);
    //         }

    //         // If not in cache, load from server
    //         const img = new Image();
    //         img.crossOrigin = "anonymous";
            
    //         const width = Math.min(tileSize, imageSize.width - (x * tileSize));
    //         const height = Math.min(tileSize, imageSize.height - (y * tileSize));
            
    //         if (width <= 0 || height <= 0) return;

    //         const imageUrl = `${process.env.REACT_APP_API_URL}/api/images/${imageName}/tile_${x}_${y}.png`;
            
    //         img.onload = async () => {
    //             // Save to cache
    //             const canvas = document.createElement('canvas');
    //             canvas.width = img.width;
    //             canvas.height = img.height;
    //             const ctx = canvas.getContext('2d');
    //             ctx.drawImage(img, 0, 0);
    //             const dataUrl = canvas.toDataURL();
    //             await tileCache.saveTile(imageId, tileId, dataUrl);
                
    //             handleTileLoad(img, x, y, tileId);
    //         };

    //         img.src = imageUrl;
    //     } catch (error) {
    //         console.error('Error loading tile:', error);
    //     }
    // };

    const loadTile = async (x, y, tileSize) => {
        return new Promise((resolve, reject) => {
            const tileId = `tile-${x}-${y}`;
            
            // Check if tile exists in cache
            if (tileCache.has(tileId)) {
                setTiles(prevTiles => {
                    if (prevTiles.some(t => t.id === tileId)) {
                        return prevTiles;
                    }
                    return [...prevTiles, tileCache.get(tileId)];
                });
                resolve();
                return;
            }
    
            const img = new Image();
            img.crossOrigin = "anonymous";
            
            const width = Math.min(tileSize, imageSize.width - (x * tileSize));
            const height = Math.min(tileSize, imageSize.height - (y * tileSize));
    
            if (width <= 0 || height <= 0) {
                resolve();
                return;
            }
    
            img.onload = () => {
                const tileData = {
                    id: tileId,
                    x,
                    y,
                    width: img.naturalWidth,
                    height: img.naturalHeight,
                    image: img
                };
    
                // Store in cache
                tileCache.set(tileId, tileData);
    
                setTiles(prevTiles => {
                    if (prevTiles.some(t => t.id === tileId)) {
                        return prevTiles;
                    }
                    return [...prevTiles, tileData];
                });
                resolve();
            };
    
            img.onerror = reject;
     
            const imageUrl = `${process.env.REACT_APP_API_URL}/api/images/${imageName}/tile_${x}_${y}.png`;
            img.src = imageUrl;
        });
    };


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
                setSearchOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        // console.log('Tiles updated:', tiles.length);
    }, [tiles]);

    // const fetchSearchResults = async (query) => {
    //     if (!query.trim()) {
    //         setSearchResults([]);
    //         return;
    //     }
    //     try {
    //         const response = await fetch(
    //             `${process.env.REACT_APP_CARDAPI_URL}/external/search-tags?q=${query}`
    //         );
    //         if (response.ok) {
    //             const data = await response.json(); 
    //             setSearchResults(data.matchedStations || []);
    //         }
    //     } catch (error) {
    //         console.error("Error fetching search results:", error);
    //     }
    // };

    const fetchSearchResults = async (query) => {
        if (!query.trim()) {
            setSearchResults([]);
            return;
        }
        try {
            const response = await fetch(
                `${process.env.REACT_APP_CARDAPI_URL}/external/search-tags?q=${query}`
            );
            if (response.ok) {
                const data = await response.json();
                const matchedStations = data.matchedStations || [];
                
                // For each matched station, check if it exists in current image
                const enrichedResults = matchedStations.map(stationId => {
                    const existsInCurrentImage = annotations.some(
                        anno => anno.station_id === stationId
                    );


 
                    var station_image_path='/';
                    if (stationId>=imgStations['/'][0] && stationId<=imgStations['/'][1]) {
                        station_image_path= '/'  
                    } 
                    if (stationId>=imgStations['/image1'][0] && stationId<=imgStations['/image1'][1]) {
                        station_image_path= '/image1'  
                    } 
                    if (stationId>=imgStations['/image2'][0] && stationId<=imgStations['/image2'][1]) {
                        station_image_path= '/image2'  
                    }  
                    return {
                        stationId,
                        existsInCurrentImage,
                        station_image_path

                    };
                });
                
                setSearchResults(enrichedResults);
            }
        } catch (error) {
            console.error("Error fetching search results:", error);
        }
    };
    
    const handleInputChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        fetchSearchResults(query);
    };

    const calculatePolygonBounds = (points) => {
        let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
    
        for (let i = 0; i < points.length; i += 2) {
            const x = points[i];
            const y = points[i + 1];
            minX = Math.min(minX, x);
            minY = Math.min(minY, y);
            maxX = Math.max(maxX, x);
            maxY = Math.max(maxY, y);
        }
    
        return {
            x: minX,
            y: minY,
            width: maxX - minX,
            height: maxY - minY,
        };
    };

    const zoomToAnnotation = (stationId) => {
        const annotation = annotations.find(anno => anno.station_id === stationId);
        if (!annotation) return;
    
        const bounds = calculatePolygonBounds(annotation.segmentation.flat());
        const stage = stageRef.current;
        const stageWidth = stage.width();
        const stageHeight = stage.height();
    
        const padding = 100;
        const scaleX = (stageWidth - padding * 2) / bounds.width;
        const scaleY = (stageHeight - padding * 2) / bounds.height;
        const newScale = Math.min(scaleX, scaleY) * 0.2;
    
        const centerX = bounds.x + bounds.width / 2;
        const centerY = bounds.y + bounds.height / 2;
    
        const newPos = {
            x: stageWidth / 2 - centerX * newScale,
            y: stageHeight / 2 - centerY * newScale
        };
    
        stage.scale({ x: newScale, y: newScale });
        stage.position(newPos);
        stage.batchDraw();
    
        setScale(newScale);
        setPosition(newPos);
    };

    const handleTouchMove = (e) => {
        e.evt.preventDefault();
        const stage = stageRef.current;
        if (!stage) return;
    
        if (e.evt.touches.length === 2) {
            // Handle pinch-zoom
            setDrag(false);
            const touch1 = e.evt.touches[0];
            const touch2 = e.evt.touches[1];
    
            const centerX = (touch1.clientX + touch2.clientX) / 2;
            const centerY = (touch1.clientY + touch2.clientY) / 2;
    
            const dist = Math.hypot(
                touch2.clientX - touch1.clientX,
                touch2.clientY - touch1.clientY
            );
    
            if (lastDistance) {
                const scaleBy = dist / lastDistance;
                const oldScale = stage.scaleX();
                const newScale = Math.min(Math.max(oldScale * scaleBy, 0.1), 10);
    
                const mousePointTo = {
                    x: (centerX - stage.x()) / oldScale,
                    y: (centerY - stage.y()) / oldScale,
                };
    
                const newPos = {
                    x: centerX - mousePointTo.x * newScale,
                    y: centerY - mousePointTo.y * newScale,
                };
    
                stage.scale({ x: newScale, y: newScale });
                stage.position(newPos);
                setScale(newScale);
                stage.batchDraw();
            }
    
            setLastDistance(dist);
        }
    };
    
    const handleTouchStart = (e, station_id) => {
        if (e.evt.touches.length === 1) {
            e.evt.preventDefault();
            e.cancelBubble = true; // Prevent stage drag
            handlePolygonClick(station_id);
        }
    };
     

    const handleTouchEnd = () => {
        setLastDistance(null);
        // setLastPosition(null);
    };

    const renderTiles = useMemo(() => {
        // console.log('Rendering tiles, count:', tiles.length);
        if (tiles.length === 0) {
            console.log('No tiles available to render');
            return null;
        }
        
        return tiles.map((tile) => { 
            return (
                <KonvaImage
                    key={tile.id}
                    image={tile.image}
                    x={tile.x * tileSize}
                    y={tile.y * tileSize}
                    width={tile.width}
                    height={tile.height}
                    listening={false}
                    imageSmoothingEnabled={false}
                    pixelRatio={2}
                />
            );
        });
    }, [tiles, tileSize]);

   const handleWheel = (e) => {
       e.evt.preventDefault();
       const stage = stageRef.current;
       const oldScale = scale;
       const pointer = stage.getPointerPosition();
   
       const mousePointTo = {
           x: (pointer.x - stage.x()) / oldScale,
           y: (pointer.y - stage.y()) / oldScale
       };
   
       const newScale = e.evt.deltaY > 0 ? oldScale * 0.9 : oldScale * 1.1;
   
       const newPos = {
           x: pointer.x - mousePointTo.x * newScale,
           y: pointer.y - mousePointTo.y * newScale
       };
   
       setScale(newScale);
       setPosition(newPos);
   };

   const categoryColors = {
       1: "#000000",
       2: "#FF0000",
       3: "#0000FF", 
       4: "#5a3c2d",
       5: "#FFFF00",
       6: "#FFA500",
       7: "#008000",
       8: "#800080",
       9: "#808080",
   };
   

   const handlePolygonClick = async (station_id) => {
    //    setSelectedPolygonId(station_id);
    //    zoomToAnnotation(station_id);
    //    setActiveStationId(station_id);
        setPolyDonations({
            donors:[],
            honorees:[],
            nations:[],
            collection:'-',
            base_amount:'$210',
            required_amount:'-'
        }); 

        await getDonationData(station_id);
        setSelectedPolygonId(station_id);
        setActiveStationId(station_id);
        zoomToAnnotation(station_id);
        setIsCardPopupOpen(true);
        setSidePanelOpen(false);  
    //    const url = `${process.env.REACT_APP_CARD_URL}/?frameId=${process.env.REACT_APP_FRAME_ID}&stationId=${station_id}`;
    //    setSidePanelOpen(true);
    //    setPanelContentUrl(url); 
   };

    

   


   const addToCart = () => {
    const cartItems = [];
    if (!localStorage.getItem(`station_cart`)) {
        cartItems.push(activeStationId);
        localStorage.setItem(`station_cart`, JSON.stringify(cartItems));
        toast.success('Item added to cart!');
    } else {
        const activeCart = localStorage.getItem(`station_cart`);
        const cItems = JSON.parse(activeCart);
        if (!cItems.includes(activeStationId)) {
            cItems.push(activeStationId);
            localStorage.setItem(`station_cart`, JSON.stringify(cItems));
            toast.success('Item added to cart!');
        } else {
            toast.info('Item is already in cart!');
        }
    }
    getMyCart();
};

   const getMyCart = () => {
        const activeCart = localStorage.getItem(`station_cart`);
        const cItems = JSON.parse(activeCart);      
        var uniqueNames = [ ...new Set(cItems) ];  
        setCartTotal(uniqueNames.length*100); 
        localStorage.setItem(`station_cart`,JSON.stringify(uniqueNames))  

        

        const eResults = uniqueNames.map(stationId => {
            const eICImage = annotations.some(
                anno => anno.station_id === stationId
            );
            var station_image_path='/';
            if (stationId>=imgStations['/'][0] && stationId<=imgStations['/'][1]) {
                station_image_path= '/'  
            } 
            if (stationId>=imgStations['/image1'][0] && stationId<=imgStations['/image1'][1]) {
                station_image_path= '/image1'  
            } 
            if (stationId>=imgStations['/image2'][0] && stationId<=imgStations['/image2'][1]) {
                station_image_path= '/image2'  
            }  
            return {
                stationId,
                eICImage,
                station_image_path

            };
        });
        // console.log('UPDATED CART',eResults);
        // console.log('NORMAL CART',uniqueNames);
        // setSearchResults(enrichedResults);


        setMyCart(eResults);
   }

   const deleteCartItem = (station_id) => { 
       const activeCart = localStorage.getItem(`station_cart`);
       if (typeof activeCart!='undefined' && activeCart!=="" && activeCart!=null) {
           const cItems = JSON.parse(activeCart);
           if (typeof cItems!='undefined' && cItems.length>0 ) {
               let arr = cItems.filter(item => item !== station_id); 
               localStorage.setItem(`station_cart`,JSON.stringify(arr)) 
           } 
           toast.error('Item removed from cart!');

       }
       getMyCart(); 
   }

   const clearCart = () => {
       localStorage.setItem(`station_cart`,'[]');
       toast.error('Cart has been cleared!');

       getMyCart(); 
   }

   const copyCart = () =>{
       getMyCart();
       const stationIdsCSV = myCart.map(result => result.stationId).join(', ');
       navigator.clipboard.writeText(stationIdsCSV)   
       alert('Copy this : '+(stationIdsCSV));
   }

   const handleCheckoutClick = () => {
       setIsPopupVisible(true);
   };

   const confirmClear = () => {
       clearCart();
       setIsPopupVisible(false);
   };

   const cancelCheckout = () => {
       setIsPopupVisible(false);
   };

   function hexToRgb(hex) {
       hex = hex.replace(/^#/, '');
       const bigint = parseInt(hex, 16);
       const r = (bigint >> 16) & 255;
       const g = (bigint >> 8) & 255;
       const b = bigint & 255;
       return [r, g, b];
   }

   const stationOpacity = (stationIds)=> {
       fetch(`${process.env.REACT_APP_CARD_BUILDER_URL}/import/fetch-station-opacity`, {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json',
           },
           body: JSON.stringify({
               station_ids: stationIds,
           }),
       })
       .then(res => res.json())
       .then(data => {
            const opacityMap = new Map();
            const stationdata = [
                {
                    idStations : 52958 ,
                    tessera_opacity : 0
                },
                {
                    idStations : 52959 ,
                    tessera_opacity : 15
                },
                {
                    idStations : 52960 ,
                    tessera_opacity : 30
                },
                {
                    idStations : 52961 ,
                    tessera_opacity :45
                },
                {
                    idStations : 52962 ,
                    tessera_opacity : 60
                },
                {
                    idStations : 52963 ,
                    tessera_opacity : 75
                },
                {
                    idStations : 52964 ,
                    tessera_opacity : 90
                } 
            ]; 
            stationdata.forEach(station=>{
                opacityMap.set(station.idStations, parseFloat(station.tessera_opacity) || 0);
            });
        //    data.data.stations.forEach(station => {
        //        opacityMap.set(station.idStations, parseFloat(station.tessera_opacity) || 0);
        //    });
           setStationApi(opacityMap);
       })
       .catch(error => {
           console.error('Error fetching data:', error);
       });
   }

   const getDonationData = async (stationId)=> {
        await fetch(`${process.env.REACT_APP_CARD_BUILDER_URL}/import/get-donation-card`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                station_id: stationId,
            }),
        })
        .then(res => res.json())
        .then(data => {
            if (typeof data.data.donation != 'undefined' && data.data.donation.length>0) {
                const donations = data.data.donation;


                const dInfo = {
                    donors:[],
                    honorees:[],
                    nations:[],
                    collection:'0',
                    base_amount:'$210',
                    required_amount:'0'
                } 
                for (let i = 0; i < donations.length; i++) {
                    if (donations[i].donor_info) {
                        const ddIn =  JSON.parse(donations[i].donor_info);
                        if (ddIn['Tribute Honoree']) {
                            dInfo.honorees.push(ddIn['Tribute Honoree']);
                        }
                        if (ddIn['Supporter First Name']) {
                            dInfo.donors.push(ddIn['Supporter First Name']+ddIn['Supporter Last Name']);
                        }
                        if (ddIn['Mailing Country']) {
                            dInfo.nations.push(ddIn['Mailing Country']);
                        } 
                    } 
                }  
                setPolyDonations(dInfo); 
            }

           
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }
 

    return (
        <div style={{ width: '100%', height: '100vh', touchAction: 'none', userSelect: 'none', overflow: 'hidden' }}>
            <ToastContainer position="top-right" autoClose={2000} />

            <header className="flex justify-between items-center p-2 bg-[#343233] text-white">
                {/* <!-- Start Section (Logo on the left) --> */}
                <div className="flex-1 text-left ms-5">
                    <img
                        draggable="false"
                        src="/logo-white.svg"
                        className="md:inline-block"
                        style={{ 
                            height: '30px'
                        }}
                        alt="Logo"
                    />
                </div>

                {/* <!-- End Section (Title on the right) --> */}
                <div className="flex-1 text-right flex justify-end items-center">
                    <p className="hidden md:block">First Century Galilee Mosaic Map</p>
                    {isMobile && (
                        <button 
                            className="text-2xl px-4 py-2"
                            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                        >
                            <i className="fa fa-bars"></i>
                        </button>
                    )}
                </div>
            </header>



            {/* <!-- Right Sidebar --> */}
                <aside 
                    className={`fixed top-23 ${isMobile ? (isSidebarOpen ? 'right-0' : '-right-64') : 'right-0'} 
                            mb-3 w-64 h-full z-[1050] bg-[#eeeeee] text-black p-4
                            transition-all duration-300 ease-in-out`}
                    onMouseEnter={() => !isMobile && setIsMouseOverSidebar(true)}
                    onMouseLeave={() => !isMobile && setIsMouseOverSidebar(false)}
                >
                <div>
                    <h2 className="text-xl text-left font-semibold mb-3">Search your mosaic tile</h2>
                </div>
                
                <div className="mt-4 space-y-6 flex flex-col justify-between items-center">
                    {/* Search Bar */}
                    <div
                        className={`flex top-4 right-4 border border-[#823a5e] px-4 py-2 rounded-lg shadow-xl items-center space-x-2`}
                    >
                        {/* <label>Search your mosaic tile</label> */}
                        <i className="fa fa-search text-[#823a5e]"></i>

                        {/* Input Box */}
                        <input
                            type="text"
                            className="p-3 h-8 w-44 bg-transperent focus:outline-none text-[#823a5e] placeholder-gray-400"
                            placeholder="Type your email or last name..."
                            value={searchQuery}
                            onChange={handleInputChange}
                            onFocus={() => setSearchOpen(true)}
                            // onBlur={() => setSearchOpen(false)}
                        />
                    </div>

                    {/* Search Results */}
                    {/* {searchOpen && searchResults.length > 0 && (
                        <div className="bg-[#823a5e] text-left  w-60 mt-2 rounded-lg shadow-lg p-4 max-h-96 overflow-y-auto">
                            {searchResults.map((station_id) => (
                                <div
                                    key={station_id}
                                    onClick={() => handlePolygonClick(station_id)}
                                    className="py-1 px-4 my-1 flex justify-between items-center bg-gray-200 rounded-md cursor-pointer"
                                > 
                                    <p>{station_id}</p> 
                                </div>
                            ))}
                        </div>
                    )} */}
                    {searchOpen && searchResults.length > 0 && (
                        <div className="bg-[#823a5e] text-left w-60 mt-2 rounded-lg shadow-lg p-4 max-h-96 overflow-y-auto">
                            {searchResults.map(({ stationId, existsInCurrentImage,station_image_path }) => (
                                <div
                                    key={stationId}
                                    onClick={() => {
                                        if (existsInCurrentImage) {
                                            handlePolygonClick(stationId);
                                        } else {
                                            // Navigate to the other image route
                                            window.location.href = station_image_path+`?station=${stationId}`;
                                        }
                                    }}
                                    className="py-1 px-4 my-1 flex justify-between items-center bg-gray-200 rounded-md cursor-pointer"
                                > 
                                    <p>{stationId}</p>
                                    {!existsInCurrentImage && (
                                        <span className="text-sm text-gray-500">(in other image)</span>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}

                    {/* My Tiles */}
                    <div
                        className={`border-2 border-[#823a5e] w-60 h-96 rounded-lg py-3 px-3 flex flex-col transition-all duration-300 ease-in-out`}
                    >
                        {/* Tile Cart Title */}
                        <div className="flex justify-between items-center pb-2">
                            <h1 className="border rounded-[5px] px-2 py-0.5  border-[#823a5e] text-[#823a5e] text-lg">My Tiles</h1>
                            {
                                myCart.length>0 ?
                                    <button className="bg-[#fff] px-3 py-1 rounded-md" onClick={handleCheckoutClick}>Clear Tiles</button>
                                :   ''
                            }
                            {/* Confirmation Popup */}
                            {isPopupVisible && (
                                <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
                                    <div className="bg-[#fff] p-6 rounded-md shadow-lg text-center">
                                        <h2 className="text-lg font-semibold mb-4">
                                           Clear Tiles
                                        </h2>
                                        <p className="mb-6">Are you sure you want to clear all items of your selected tiles?</p>
                                        <div className="flex justify-center gap-4">
                                            <button
                                                className="bg-[#e9ecef] px-4 py-2 rounded-md border border-gray-500"
                                                onClick={cancelCheckout}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="bg-[#823a5e] text-white px-4 py-2 rounded-md"
                                                onClick={confirmClear}
                                            >
                                                Yes, Clear Tiles
                                            </button>
                                            
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Cart Details (Hidden When Search is Open) */} 
                        <>
                            <div className="flex-1 overflow-y-auto">
                                <div 
                                    className="bg-[#eeeeee] px-3 my-2 py-1 rounded-md flex justify-between items-center"
                                >
                                    <button>Total : $ {cartTotal}</button> 
                                </div>
                                {myCart.map((item, index) => (
                                    <div
                                        key={index} // Ensure unique key for React list rendering
                                        className="bg-[#fff] px-3 my-2 py-1 rounded-md flex justify-between items-center"
                                    >
                                        <button
                                        onClick={() => {
                                            if (item.eICImage) {
                                                handlePolygonClick(item.stationId);
                                            } else {
                                                // Navigate to the other image route
                                                window.location.href = item.station_image_path+`?station=${item.stationId}`;
                                            }
                                        }}
                                        // onClick={() => handlePolygonClick(item.stationId)}
                                        
                                        >{item.stationId}</button>
                                        <button className="border border-slate-500 px-2 rounded-md"><i className="fa fa-close" onClick={() => deleteCartItem(item.stationId)}></i></button>
                                    </div>
                                ))}
                                {
                                    myCart.length<=0 ?
                                        <div 
                                            className="bg-[#eeeeee] px-3 my-2 py-1 rounded-md flex justify-between items-center"
                                        >
                                            <button>No Items Found</button> 
                                        </div>  
                                    :'' 
                                }
                            </div>

                            {/* Fixed Button */}
                            {
                                myCart.length>0 ?
                                    <div className="mt-2">
                                        <button className="bg-[#fff] rounded-md text-sm px-5 py-1 w-full" onClick={() => copyCart()}>Copy Tile Codes</button>
                                        <div className="border rounded-[5px] px-2 py-0.5  border-[#823a5e] text-[#823a5e] font-bold text-sm mt-2" >
                                            <a target='_blank'  rel="noreferrer" href={process.env.REACT_APP_DONATION_LINK} >Sponsor Tile</a>
                                        </div>
                                    </div>
                                :   ''
                            } 
                        </> 
                    </div>
                </div>
            </aside>

            {/* Main Stage Container */}
            <div className={`z-0 ${isMobile && sidePanelOpen ? 'h-[50vh]' : 'h-full'}`}>
                {position && (
                    <Stage
                        ref={stageRef}
                        width={window.innerWidth}
                        height={isMobile && sidePanelOpen ? window.innerHeight * 0.5 : window.innerHeight}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                        onWheel={handleWheel}
                        scale={{ x: scale, y: scale }}
                        position={position}
                        draggable={drag && !isMouseOverSidebar}
                    >
                            <Layer>
                                <Group>
                                    {renderTiles}
                                </Group>
                                {loading === false && annotations.map((annotation, index) => {
                                    const stationValue = stationApi.get(annotation.station_id);
                                    const calculatedOpacity = stationValue > 0 ? stationValue / 100 : 0;
                                    const fillColor = stationValue
                                        ? `rgba(${hexToRgb(categoryColors[annotation.category_id]).join(',')}, ${calculatedOpacity})`
                                        : 'transparent'; 

                                    const isSelected = selectedPolygonId === annotation.station_id;
                                    const baseStrokeWidth = 1 / scale;
                                    const strokeWidth = isSelected ? baseStrokeWidth * 5 : baseStrokeWidth;

                                    return (
                                        <Line
                                            key={index} 
                                            points={annotation.segmentation.flat()}
                                            closed
                                            fill={fillColor}
                                            stroke={categoryColors[annotation.category_id]}
                                            strokeOpacity={4}
                                            strokeWidth={strokeWidth}
                                            onClick={() => handlePolygonClick(annotation.station_id)}
                                            onTouchStart={(e) => handleTouchStart(e, annotation.station_id)}
                                            onMouseEnter={e => {
                                                e.target.strokeWidth(strokeWidth);
                                                e.target.getLayer().batchDraw();
                                            }}
                                            onMouseLeave={e => {
                                                e.target.strokeWidth(strokeWidth);
                                                e.target.getLayer().batchDraw();
                                            }}
                                        />
                                    );
                                })}  
                            </Layer>
                    </Stage>
                    
                )} 
            </div>

            
            
            {/* Search Bar */}  
            {isCardPopupOpen ? (
                <CardPopup 
                    onClose={() => setIsCardPopupOpen(false)}
                    stationId={activeStationId}
                    setSidePanelOpen={setSidePanelOpen}
                    setPanelContentUrl={setPanelContentUrl}
                />
            ) : sidePanelOpen && (
                <div className={`side-panel fixed ${isMobile ? 'bottom-0' : 'bottom-0 left-0'} 
                                ${isMobile ? 'h-[50%] w-full' : 'h-[80%] md:w-[20%] w-[30%]'}
                                bg-white shadow-md z-[1000]`}
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        zIndex: 1000,
                        ...(isMobile && {
                            top: 'auto',
                            height: '50%'
                        })
                    }}
                >
                    <header className="bg-[#eeeeee] flex justify-between items-center px-4 w-full h-12 z-[1050] absolute top-0">
                        <div>
                            <button 
                                className="px-4 py-2 bg-[#823a5e] text-white rounded"
                                onClick={addToCart}
                            >
                                Add to Cart
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={() => setSidePanelOpen(false)}
                                className="px-4 py-2 bg-[#823a5e] text-white font-bold rounded"
                            >
                                X
                            </button>
                        </div>
                    </header>

                    <iframe
                        allowFullScreen={true}
                        allow="fullscreen; accelerometer; gyroscope; magnetometer; vr; autoplay; camera; microphone; display-capture; xr-spatial-tracking"
                        src={panelContentUrl}
                        style={{ 
                            position: "absolute", 
                            left: "0px", 
                            top: "48px",
                            width: "100%", 
                            height: "calc(100% - 48px)"
                        }}
                        title="side panel"
                        onError={(e) => console.log('iframe error:', e)}
                    /> 
                </div>
            )}

            
        </div>
    );

}