import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css';
// import Home from './Home';
import jsonData2 from './2.json';
import jsonData561 from './561.json';
import jsonData571 from './571.json';
// import jsonData2 from './new.json';
// import ImageDisplay from './ImageDisplay';
// import ImageDisplayNew from './ImageDisplayNew';
import './index.css'
import DynamicChunk from './DynamicChunk.jsx';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* <Route path='/home' element={<Home jsonData={jsonData,jsonData1,jsonData2}></Home>} /> */}
          <Route 
            path='/' 
            element={
              <DynamicChunk 
                jsonData={jsonData2}
                // jsonData561={jsonData561}
                // jsonData571={jsonData571}
              />
            } 
          />
          <Route 
            path='/image1' 
            element={
              <DynamicChunk 
                jsonData={jsonData561}
                // jsonData561={jsonData561}
                // jsonData571={jsonData571}
              />
            } 
          /> 
          <Route
          path='/image2'
          element={
            <DynamicChunk
              jsonData={jsonData571}
            />
          }
          />  
          
           {/* <Route path='/dynamic' element={<ImageDisplay jsonData={jsonData}></ImageDisplay>} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
